import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="role-form"
export default class extends Controller {
  static targets = ['div', 'select']

  connect() {
    let divTarget = this.divTarget.id
    get(`/user/user_creation?target=${divTarget}&role=${this.selectTarget.value}`, {
      responseKind: 'turbo-stream'
    })
  }

  role_change(event) {
    let divTarget = this.divTarget.id
    let user_id = event.target.getAttribute('data-user_id')

    if (user_id) {
      get(`/user/user_creation?target=${divTarget}&user_id=${user_id}&role=${event.target.value}`, {
        responseKind: 'turbo-stream'
      })
    }
    else {
      get(`/user/user_creation?target=${divTarget}&role=${event.target.value}`, {
        responseKind: 'turbo-stream'
      })
    }
  }

}
